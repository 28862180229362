import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Router from 'vue-router' // 关于路由报错
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'assets/style/iconfont.css';
import 'assets/style/iconfont/iconfont.css';
import 'assets/style/index.scss';
import Api from './utils/api.js';
import Http from './utils/http.js';
import locker from "lockr";
import lsbridge from "lsbridge";
import common from "./utils/common";
import moment from "moment";
import './common/font.css'
import "video.js/dist/video-js.css"

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Router) // 关于路由报错
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.prototype.$api = Api;
Vue.prototype.$http = Http;
Vue.prototype.$lockr = locker;
Vue.prototype.$lsbridge = lsbridge;
Vue.prototype.$common = common;   //公共方法封装类
Vue.prototype.$moment = moment;
Vue.filter('showNumber', function(value) {
    if (value === null) {
        return '-'
    }
    return value
})

// 关于路由报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')