<template>
    <el-tabs v-model="decorationTab" type="card" class="education-content tabs-full"
             @tab-click="toggleOperationTab">
        <el-tab-pane label="装修管理" name="decorationData">
           <keep-alive>
            <router-view />
           </keep-alive>
        </el-tab-pane>
        <el-tab-pane label="店铺注册" name="adminRegisterData">
          <router-view />
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    export default {
        name:'Decoration',
        data() {
            return {
                decorationTab: 'decorationData',
            }
        },
      created() {
        if (this.$route.meta?.parentName) {
          this.decorationTab = this.$route.meta.parentName
        } else {
          this.decorationTab = this.$route.name
        }
      },
      watch: {
        $route(route) {
          if (route.meta?.parentName) {
            this.decorationTab = route.meta.parentName
          } else {
            this.decorationTab = route.name
          }
        }
      },
      methods: {
        toggleOperationTab(tab) {
          this.$router.push({
            name: tab.name,
          });
        },
      },
    }
</script>

<style scoped lang="scss">
    .education-content {
        height: 100%;
    }
</style>