<template>
    <div class="operation-wrapper">
        <el-tabs v-model="operationTab" type="card" class="tabs-full" @tab-click="toggleOperationTab">
            <el-tab-pane label="数据模板管理" name="dataTemplateManager"></el-tab-pane>
            <el-tab-pane label="数据管理" name="dataAnalysisManager"></el-tab-pane>
        </el-tabs>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                // operationTab: 'dataAnalysisManager'
                operationTab: 'dataTemplateManager'
            }
        },
        created() {
            this.operationTab = this.$route.meta.tabName;
        },
        watch: {
            $route(route) {
                if (route.name === 'dataTemplateManager') {
                    // this.$router.push('/admin/operatedata/index')
                    this.operationTab = 'dataTemplateManager'
                }
                if (route.name === 'dataAnalysisManager') {
                    this.operationTab = 'dataAnalysisManager'
                }

            }
        },
        methods: {
            toggleOperationTab(tab) {
                this.$router.push({
                    name: tab.name
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .operation-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        .container {
            flex: 1;
            background: #fff;
            overflow: hidden;
        }
    }
</style>