import { Message, MessageBox } from "element-ui";
import moment from "moment";
import { Base64 } from 'js-base64';
import _ from "underscore";
import locker from 'lockr';
import CryptoJs from "crypto-js";
const common = {
    axiosErrorMsg(err) {
        if (err.response) {
            err.response.data.msg ? Message.error(err.response.data.msg) : '服务异常';
        } else {
            err.message ? Message.error(err.message) : '服务异常';
        }
    },
    formatTimeCount(time) {
        if (time <= 0) {
            return '00:00:00';
        }
        let day = 0;
        if ((time / (24 * 3600)) > 1) {
            day = Math.floor(time / (24 * 3600));
            time = time % (3600 * 24);
        }
        let hours = Math.floor(time / 3600);
        hours = hours < 10 ? `0${hours}` : hours;
        let left_second = time % 3600;
        let minute = Math.floor(left_second / 60);
        minute = minute < 10 ? `0${minute}` : minute;
        let second = left_second % 60;
        second = second < 10 ? `0${second}` : second;
        if (day) {
            return day < 10 ? `0${day}:${hours}:${minute}:${second}` : `${day}:${hours}:${minute}:${second}`;
        } else {
            return `${hours}:${minute}:${second}`;
        }
    },
    formatTimeStr(timeStr) { //格式化时间戳-xxxx-xx-xx xx:xx:xx
        let time = new Date(timeStr);
        return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
    random_str(length) {
        let ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        ALPHABET += 'abcdefghijklmnopqrstuvwxyz';
        ALPHABET += '0123456789-_';
        let str = '';
        for (let i = 0; i < length; ++i) {
            let rand = Math.floor(Math.random() * ALPHABET.length);
            str += ALPHABET.substring(rand, rand + 1);
        }
        return str;
    },
    /**
     * base64加密
     * @param string
     */
    encryptCode(string) {
        let num = Math.floor(Math.random() * 11) + 10; //生成10 ~ 20的数字
        let randomStr = this.random_str(num);
        let keyStr = this.random_str(num);
        let tmpStr = Base64.encode(string) + randomStr;
        tmpStr = Base64.encode(randomStr + Base64.encode(tmpStr));
        return { keyStr, tmpStr };
    },
    /**
     * base64解密
     * @param string
     * @param key
     */
    decryptCode(string, key) {
        let num = key.length;
        let tmpStr = Base64.decode(string);
        tmpStr = tmpStr.substring(num);
        tmpStr = Base64.decode(tmpStr);
        let len = tmpStr.length - num;
        let codeStr = tmpStr.substring(0, len);
        return Base64.decode(codeStr);
    },
    sortByIndex(rank, price) {
        let bid_position = 0;
        _.each(rank, (item, index) => {
            if (price >= parseFloat(item) && index === 0) {
                bid_position = 1;
            } else if (
                bid_position === 0 && price <= parseFloat(item) &&
                price >= parseFloat(rank[index + 1]) &&
                index < rank.length
            ) {
                bid_position = index + 2;
            } else if (bid_position === 0 && index === rank.length - 1) {
                bid_position = rank.length + 1;
            }
        });
        return bid_position;
    },
    subtraction(val1, val2) {
        return Number((val1 - val2).toFixed(2));
    },
    addByRanking(index) {
        let rate = 0;
        switch (Number(index)) {
            case 1:
                rate = 6;
                break;
            case 2:
                rate = 5;
                break;
            case 3:
                rate = 4;
                break;
            case 4:
                rate = 3;
                break;
            case 5:
                rate = 3;
                break;
            case 6:
                rate = 3;
                break;
            case 7:
                rate = 2;
                break;
            case 8:
                rate = 2;
                break;
            case 9:
                rate = 2;
                break;
            case 10:
                rate = 2;
                break;
            default:
                rate = 1;
        }
        return rate;
    },
    toFormat2dot(val) {
        return Number(val.toFixed(2));
    },
    calculateStoreData(store_data) {
        // 0-6 每个小时店铺的出价排名
        let time_offer = [];
        let time_offer_arr = this.getStoreOffer(store_data.store_num, store_data.time1_bid, store_data.time1_bid_wave, 6);
        time_offer = time_offer.concat(time_offer_arr);
        //7-12
        let time_offer_arr1 = this.getStoreOffer(store_data.store_num, store_data.time2_bid, store_data.time2_bid_wave, 6);
        time_offer = time_offer.concat(time_offer_arr1);
        //13-18
        let time_offer_arr2 = this.getStoreOffer(store_data.store_num, store_data.time3_bid, store_data.time3_bid_wave, 6);
        time_offer = time_offer.concat(time_offer_arr2);
        //19-24
        let time_offer_arr3 = this.getStoreOffer(store_data.store_num, store_data.time4_bid, store_data.time4_bid_wave, 6);
        time_offer = time_offer.concat(time_offer_arr3);
        return time_offer;
    },
    //时间段内每个店铺的出价
    getStoreOffer(store_num, base_num, wave_range, time_num) {
        let time_offer = [];
        for (let i = 0; i < time_num; i++) {
            let store_offer = [];
            for (let j = 0; j < store_num; j++) {
                let offer = this.getOffer(base_num, wave_range);
                store_offer.push(offer);
            }
            let store_offer_sort = _.sortBy(store_offer, (item) => {
                return item;
            })
            time_offer.push(store_offer_sort);
        }
        return time_offer;
    },
    //获取区间内的随机数
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + Number(min);
    },
    //获取出价
    getOffer(base_num, wave_range) {
        let [min, max] = wave_range.split('#');
        let value = this.getRandomInt(min, max);
        return (100 + Number(value)) * base_num / 100;
    },
    //每个店铺一天的平均出价
    getAvgStoreOffer(time_offer, store_num) {
        let avg_store_arr = [];
        for (let i = 0; i < store_num; i++) {
            let total = 0;
            _.each(time_offer, (item) => {
                total += Number(item[i]);
            });
            avg_store_arr.push(this.toFormat2dot(total / 24));
        }
        return avg_store_arr;
    },
    //将数组的元素转为整型，并且倒叙
    numberReverse(arr) {
        let tmp_arr = [];
        tmp_arr = _.map(arr, (item) => {
            return Number(item);
        });
        tmp_arr = _.sortBy(tmp_arr, (item) => {
            return -item
        });
        return tmp_arr;
    },
    isCompetion() {
        if (Number(locker.get('competition_type')) === 1) {
            return true;
        } else {
            return false;
        }
    },
    encrypte(text) {
        if (typeof text !== 'string') {
            text = text.toString();
        }
        return CryptoJs.AES.encrypt(text, 'yike').toString();
    },
    decrypte(string) {
        let bytes = CryptoJs.AES.decrypt(string, 'yike');
        return bytes.toString(CryptoJs.enc.Utf8);
    },
    numberToAlphabet(val) {
        let Alphabets = 'ABCDEFGHIJKLMNOPQ';
        val = Number(val);
        return Alphabets[val];
    },
    formatJson(form_data) {
        let data = {
            title: form_data.title,
            category_id: form_data.category_id,
            answer: JSON.parse(form_data.answer)
        }
        let options = [];
        JSON.parse(form_data.options).map((item) => {
            options.push({name: item});
        })

        return {
            data,
            options,
            category_arr: form_data.category_id_arr
        }
    },
    formatFillBlank(form_data) {
        let data = {
            title: form_data.title,
            category_id: form_data.category_id,
            answer: JSON.parse(form_data.answer),
            is_order_answer: form_data.is_order_answer ? true : false
        }
        let options = [];
        JSON.parse(form_data.answer).map((item) => {
            options.push({name: item});
        })

        return {
            data,
            options,
            category_arr: form_data.category_id_arr
        }
    },
    formatAsk(formdata) {
        let data = {
            title: formdata.title,
            category_id: formdata.category_id,
            options: '[]',
            analysis: formdata.analysis,
            answer: JSON.parse(formdata.answer)[0]
        };
        return {
            data,
            category_arr: formdata.category_id_arr
        }
    },
    formatTopicLabel(index, labelIndex=true) {
        let label = '';
        switch (index.trim()) {
            case 'single_data':
                label = labelIndex ? '一、单选题' : '单选题';
                break;
            case 'multiple_data':
                label = labelIndex ? '二、多选题' : '多选题';
                break;
            case 'judge_data':
                label = labelIndex ? '三、判断题' : '判断题';
                break;
            case 'blank_data':
                label = labelIndex ? '四、填空题' : '填空题';
                break;
            case 'answer_data':
                label = labelIndex ? '五、问答题' : '问答题';
                break;
        }
        return label;
    },
    formatSortIndex(index, object) {
        let list = [];
        for (let key in object) {
            if (object[key].total) {
                list.push(key);
            }
        }
        let index_name = '';
        list.map((item, key) => {
            if (item === index) {
                index_name = key;
            }
        });
        let title_txt = this.formatTopicLabel(index,false);
        let index_chinese = this.indexToChinese(index_name);
        return `${index_chinese}、${title_txt}`;
    },
    indexToChinese(index) {
        let chinese = '一二三四五六七八九十';
        return chinese[index];
    },

    copyTemplatePack(request, params) {
        return new Promise(async(resolve, reject) => {
          const result = await MessageBox.confirm("是否复制一个副本？", "提示", {
            confirmButtonText: "确认复制",
            cancelButtonText: "取消",
            type: "warning",
            confirmButtonClass: 'btn-blue',
          }).catch(e => {});
          if(result) {
            const res = await request(params);
            if(res.code != 200) {
                Message.error(res.msg);
                resolve(false);
                return void 0;
            }
            Message.success(res.msg);
            resolve(true);
            // if()
          }
        })
      },

    sendData(request,params){
        
            return new Promise(async(resolve, reject) => {
                const result = await MessageBox.confirm("是否发送？", "提示", {
                  confirmButtonText: "确认发送",
                  cancelButtonText: "取消",
                  type: "warning",
                  confirmButtonClass: 'btn-blue',
                }).catch(e => {});
                if(result) {
                  const res = await request(params);
                  if(res.code != 200) {
                      Message.error(res.msg);
                      resolve(false);
                      return void 0;
                  }
                  Message.success(res.msg);
                  resolve(true);
                }
              })
    }



}
export default common;