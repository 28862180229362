<template>
    <div class="operation-wrapper">
        <el-tabs v-model="operationTab" type="card" class="tabs-full" @tab-click="toggleOperationTab">
            <el-tab-pane label="运营管理" name="operateData"></el-tab-pane>
            <el-tab-pane label="店铺选品" name="operateDataStoreSelection"></el-tab-pane>
            <el-tab-pane label="店铺营销" name="operateDataStoreMarket"></el-tab-pane>
            <el-tab-pane label="搜索广告" name="operateDataSearchAds"></el-tab-pane>
            <el-tab-pane label="热门关键词" name="operateDataHotKeywords"></el-tab-pane>
            <el-tab-pane label="搜索广告商品" name="operateDataGoodsInfo"></el-tab-pane>
            <el-tab-pane label="展位广告" name="operateDataBoothAdvertising"></el-tab-pane>
            <el-tab-pane label="商品信息" name="operateDataGoodsAttr"></el-tab-pane>
        </el-tabs>
        <div class="container">
                <keep-alive include="StoreMarket,BoothAdvertising,GoodsAttr">
                    <router-view />
                </keep-alive>
        </div>
    </div>
</template>

<script>
    export default {
        name:'operationTab',
        data() {
            return {
                operationTab: 'operateData'
            }
        },
        created() {
            this.operationTab = this.$route.meta.tabName;
        },
        watch: {
            $route(route) {
                if (route.name === 'operateData') {
                    // this.$router.push('/admin/operatedata/index')
                    this.operationTab = 'operateData'
                }
            }
        },
        methods: {
            toggleOperationTab(tab) {
                this.$router.push({
                    name: tab.name
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .operation-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        .container {
            // height: 100%;
            flex: 1;
            background: #fff;
            overflow: hidden;
        }
    }
</style>