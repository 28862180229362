import axios from "axios";
import router from '../router'
import {Loading, Message, MessageBox} from 'element-ui';

const axiosInstance = axios.create({
	timeout: 60000
});

//请求拦截器
axiosInstance.interceptors.request.use((config) => {
	if (localStorage.getItem('token')) {
		config.headers.Authorization = localStorage.getItem('token');
	}
	return config;
}, (error) => {
	return Promise.reject(error);
})

//响应拦截器
axiosInstance.interceptors.response.use((response) => {
	if(response.data.status === 204) {
		Message.warning(response.data.msg)
		setTimeout(() =>{
			localStorage.clear()
			router.push({
				path: '/',
			})
		}, 2000)
	}
	return response.data;
}, (error) => {
	return Promise.reject(error);
})

const get = (url, param={}) => {
	return axiosInstance.get(url, {params: param})
}

const post = (url, formdata = {}, config={}) => {
	return axiosInstance.post(url, formdata, config)
}

export {get, post}