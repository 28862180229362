<template>
    <div class="student-container">
        <div class="left-wrapper">
            <div class="left-header">
                <div class="logo">
                    <i class="iconfont">&#xe63c;</i>
                </div>
                <p class="theme">运营推广</p>
            </div>
            <el-scrollbar class="left-menu-wrapper" :native="false">
                <el-menu
                        :default-active="menuActive"
                        class="el-menu-vertical-demo left-menu"
                        @open="handleOpen"
                        @close="handleClose"
                        v-if="selectTabIndex === 0">
                    <el-menu-item index="operationPromotionManage">
                        <router-link to="/student/operationpromotion/index" class="nav-item">
                            <i class="iconfont">&#xe64a;</i>
                            商品采购
                        </router-link>
                    </el-menu-item>
                    <el-menu-item index="operationPromotionGoodsManage">
                        <router-link to="/student/operationpromotion/goodsmanage" class="nav-item">
                            <i class="iconfont">&#xe889;</i>
                            商品管理
                        </router-link>
                    </el-menu-item>
                    <el-menu-item>
                        <router-link to="/student/operationpromotion/industrymarket" target="_blank" class="nav-item">
                            <i class="iconfont">&#xe63c;</i>
                            数据分析
                        </router-link>
                    </el-menu-item>
                </el-menu>
                <el-menu
                        :default-active="menuActive"
                        class="el-menu-vertical-demo left-menu"
                        @open="handleOpen"
                        @close="handleClose"
                        v-if="selectTabIndex === 1">
                    <el-submenu index="operationPromotionStorePromotion">
                        <template slot="title">
                            <router-link to="/student/operationpromotion/storepromotion"><i class="iconfont">&#xe610;</i>营销工具</router-link>
                        </template>
                        <el-menu-item index="operationPromotionStorePromotion">
                            <div class="nav-item">
                                <router-link to="/student/operationpromotion/storepromotion">店铺促销</router-link>
                            </div>
                        </el-menu-item>
                        <el-menu-item index="operationPromotionSetPromotion">
                            <div class="nav-item">
                                <router-link to="/student/operationpromotion/setpromotion">套装促销</router-link>
                            </div>
                        </el-menu-item>
                        <el-menu-item index="operationPromotionCoupon">
                            <div class="nav-item">
                                <router-link to="/student/operationpromotion/coupon">优惠券</router-link>
                            </div>
                        </el-menu-item>
                    </el-submenu>
                    <!--<div @click="deleSelectPromotion" class="endPromotion">-->
                    <!--<i class="iconfont">&#xe6a2;</i>-->
                    <!--<span>结束推广</span>-->
                    <!--</div>-->
                </el-menu>
                <el-menu
                        :default-active="menuActive"
                        class="el-menu-vertical-demo left-menu"
                        @open="handleOpen"
                        @close="handleClose"
                        v-if="selectTabIndex === 2">
                    <el-submenu index="operationPromotionPromotionManagement">
                        <template slot="title">
                            <router-link to="/student/operationpromotion/promotionmanagement"><i class="iconfont">&#xe63c;</i>展位广告</router-link>
                        </template>
                        <el-menu-item index="operationPromotionPromotionManagement">
                            <div class="nav-item">
                                <router-link to="/student/operationpromotion/promotionmanagement">推广管理</router-link>
                            </div>
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="operationPromotionSearchAds">
                        <template slot="title">
                            <router-link to="/student/operationpromotion/searchads"><i class="iconfont">&#xe69a;</i>搜索广告</router-link>
                        </template>
                        <el-menu-item index="operationPromotionSearchAds">
                            <div class="nav-item">
                                <router-link to="/student/operationpromotion/searchads">推广管理</router-link>
                            </div>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="operationPromotionShopCustomerAnalysis">
                        <router-link to="/student/operationpromotion/shopcustomeranalysis" class="nav-item" target="_blank">
                            <i class="iconfont">&#xe63c;</i>
                            数据分析
                        </router-link>
                    </el-menu-item>
                </el-menu>
            </el-scrollbar>
            <div class="left-download">
                <div class="download-item">
                    <span class="left">活动促销策划案</span>
                    <a :href="downFile" class="right">下载素材</a>
                </div>
            </div>
        </div>
        <div class="right-wrapper">
            <div class="right-header">
                <div class="header-tab">
                    <div class="item-tab" v-for="(item, index) in tabList" :class="{activeSelect: selectTabIndex === index}" @click="selectTab(index)">
                        <router-link class="" :to="item.route">{{item.name}}</router-link>
                    </div>
                </div>
                <div class="countdown">
                    <!--倒计时-->
                    <CountDown></CountDown>
                </div>
                <div class="user-wrapper">
                    <div class="user-info">
                        <div class="user-avatar">
                            <img :src="userIcon ? userIcon : require('../../../assets/images/user_img.png')" alt="" />
                        </div>
                        <div class="user-name">{{username}}</div>
                    </div>
                    <div class="exit-btn" @click="backTo">
                        <i class="iconfont">&#xe642;</i>
                        <span>返回主界面</span>
                    </div>
                </div>
            </div>
            <el-scrollbar class="right-content" :native="false">
                <div class="right-wrapper-content">
                    <router-view/>
                </div>
            </el-scrollbar>
            <el-dialog
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="608px">
                <div class="train-dialog-content">
                    <span>1. 点击确定提交答案并返回首页，您可以重新进来编辑提交，成绩以最后提交为准</span>
                    <span>2. 点击取消继续留在当前页面作答</span>
                    <span>3. <b style="color: red">*注意：</b>店铺引流-搜索广告-推广管理：创建好的推广计划，要先点击结束推广，才算完成答题，可以重新编辑后重新结束推广</span>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="endPromotion" class="btn-red">确 定</el-button>
            </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import CountDown from '../../../components/student/CountDown.vue'
    export default {
        name: "TrainAdminLayout",
        data() {
            return {
                menuActive: '',
                username: '',
                userIcon: '',
                tabList: [
                    {
                        id: 1,
                        name: '店铺选品',
                        route: '/student/operationpromotion/index'
                    },
                    {
                        id: 2,
                        name: '店铺营销',
                        route: '/student/operationpromotion/storepromotion'
                    },
                    {
                        id: 3,
                        name: '店铺引流',
                        route: '/student/operationpromotion/promotionmanagement'
                    }
                ],
                selectTabIndex: 0,
                tabRouteList:['PlanStepOne','PlanStepTwo','PlanStepThree','PlanStepSucceed','operationPromotionPromotionManagement'],
                shopPromotion:['operationPromotionStorePromotionAdd','operationPromotionStorePromotionOne','operationPromotionStorePromotionTwo'],
                setMenuList:['operationPromotionSetPromotionOne'],
                couponList:['operationPromotionCouponAdd','operationPromotionCouponOne','operationPromotionCouponTwo'],
                downFile: null,
                dialogVisible: false
            }
        },
        components: {
            CountDown
        },
        watch: {
            $route(route) {
                const searchAdsActive = ['CreatePlanStepOne','CreatePlanStepTwo','CreatePlanStepThree','CreatePlanStepFour', 'studentCreatePlan']
                let route_name = route.matched[1].name
                if (searchAdsActive.indexOf(route_name) !== -1) {
                    route_name = 'operationPromotionSearchAds';
                }
                this.menuActive = route_name;
                this.setActiveTab(route.matched[0].name);
                this.serActiveTabTwo()
            },
        },
        mounted () {
            //获取运营菜单列表
            this.$http.axiosGet(this.$api.data_userOpList, (res) => {
                // console.log('res',res)
            }, (err) => {
                console.log(err);
            })
            this.username = localStorage.getItem('username');
            let userIcon = localStorage.getItem('headerImg');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
            this.setActiveTab(this.$route.matched[0].name);
            this.getDownFile()
        },
        created() {
            this.menuActive = this.$route.matched[1].name;
            this.serActiveTabTwo()
        },
        methods: {
            //设置路由
            setActiveTab(routeName) {
                if (routeName === 'shopProduct') {
                    this.selectTabIndex = 0;
                } else if (routeName === 'shopDiscount') {
                    this.selectTabIndex = 1;
                } else {
                    this.selectTabIndex = 2;
                }
            },
            serActiveTabTwo(){
                this.tabRouteList.map(item=>{
                    if(this.$route.name === item){
                        this.menuActive = 'operationPromotionPromotionManagement';
                        this.selectTabIndex = 2;
                        return;
                    }
                })
                this.shopPromotion.map(item=>{
                    if(this.$route.name === item){
                        this.menuActive = 'operationPromotionStorePromotion';
                        this.selectTabIndex = 1;
                        return;
                    }
                })
                this.couponList.map(item=>{
                    if(this.$route.name === item){
                        this.menuActive = 'operationPromotionCoupon';
                        this.selectTabIndex = 1;
                        return;
                    }
                })
                if(this.$route.name == 'operationPromotionSetPromotionOne'){
                    this.menuActive = 'operationPromotionSetPromotion',
                        this.selectTabIndex = 1;
                    return;
                }
            },
            // 返回主界面
            backTo() {
                // this.$router.push('/student/index');
                this.dialogVisible = true;
            },
            //点击返回主界面时，选择要不要结束推广，确定时调用
            endPromotion() {
                let formData = new FormData();
                this.$http.axiosPost(this.$api.storedrainage_endoperation, formData, (res) => {
                    if (res.code === 200) {
                        this.dialogVisible = false;
                        this.$router.push('/student/competition/index');
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //选择模块
            selectTab(index) {
                this.selectTabIndex = index;
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            // 获取下载文件路径
            getDownFile() {
                this.$http.axiosGet(this.$api.showStoreMarket, (res) => {
                    if (res.code === 200) {
                        this.downFile = res.data.data[0].store_file.src;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //结束推广
            deleSelectPromotion(){
                this.$confirm('确定要结束推广么？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosGet(this.$api.endMarketStu, res=>{
                        if(res.code === 200){
                            this.$message({
                                type: 'success',
                                message: '结束成功!'
                            });
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消结束'
                    });
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .student-container {
        background: #F7F9FB;
        width: 100vw;
        height: 100vh;
        display: flex;
        .left-wrapper {
            width: 280px;
            background: #2C405F;
            height: 100%;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            .left-header {
                text-align: center;
                .logo {
                    margin-top: 36px;
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    background: #FD4446;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 60px;
                    .iconfont {
                        display: block;
                        font-size: 33px;
                        color: #fff;
                    }
                }
                .theme {
                    margin-top: 22px;
                    font-size: 28px;
                    color: #fff;
                    line-height: 1;
                }
            }
            .left-menu-wrapper {
                margin-top: 50px;
                height: 1%;
                flex: 1;
                /deep/ .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .left-menu {
                    height: 100%;
                    background: transparent;
                    border-right: none;
                }
                .el-menu-item {
                    height: 60px;
                    line-height: 60px;
                    padding: 0!important;
                    a.nav-item {
                        height: 100%;
                        color: #fff;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 0 40px;
                        position: relative;
                        transition: all .3s;
                        .iconfont {
                            color: #F7F9FB;
                            font-size: 20px;
                            margin-right: 20px;
                            transition: all .3s;
                        }
                    }
                    &:focus, &:hover, &.is-active {
                        background-color: #25354F;
                    }
                    /*&.is-active, &.is-opened {*/
                    &.is-active {
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            width: 2px;
                            height: 100%;
                            background: #FF4400;
                        }
                    }
                }
                .el-submenu {
                    height: auto;
                    line-height: 60px;
                    padding: 0!important;
                    /deep/ .el-submenu__title {
                        height: 100%;
                        color: #F7F9FB;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 0 40px !important;
                        position: relative;
                        transition: all .3s;
                        .iconfont {
                            color: #F7F9FB;
                            font-size: 20px;
                            margin-right: 20px;
                            transition: all .3s;
                        }
                        a {
                            color: #fff;
                        }
                        /deep/ .el-submenu__icon-arrow {
                            color: #fff;
                            transform: rotateZ(270deg);
                        }
                        &:focus, &:hover, &.is-active {
                            background-color: #25354F;
                        }
                        &:focus {
                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                width: 2px;
                                height: 100%;
                                background: #FF4400;
                            }
                        }
                    }
                    &:focus, &:hover, &.is-active {
                        background-color: #25354F;
                    }
                    /*&.is-active, &.is-opened {*/
                    /*&:before {*/
                    /*content: '';*/
                    /*position: absolute;*/
                    /*left: 0;*/
                    /*width: 2px;*/
                    /*height: 100%;*/
                    /*background: #FF4400;*/
                    /*}*/
                    /*}*/
                    /deep/  .el-menu {
                        background: #2C405F;
                        .el-menu-item {
                            height: 60px;
                            line-height: 60px;
                            padding: 0!important;
                            .nav-item {
                                height: 100%;
                                color: #F7F9FB;
                                font-size: 18px;
                                display: flex;
                                align-items: center;
                                padding: 0 20px 0 40px;
                                position: relative;
                                transition: all .3s;
                                a {
                                    display: block;
                                    width: 100%;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            .left-download {
                margin-bottom: 100px;
                color: #fff;
                .download-item {
                    padding: 0 25px 0 40px;
                    line-height: 40px;
                    display: flex;
                    justify-content: space-between;
                    .right {
                        color: #FD4446;
                    }
                }
            }
        }
        .right-wrapper {
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .right-header {
                height: 80px;
                background: #fff;
                border-bottom: 1px solid #D1D2DB;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .header-tab {
                    height: 100%;
                    position: absolute;
                    left: 30px;
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    color: #333333;
                    .activeSelect {
                        a {
                            display: block;
                            height: 100%;
                            color: #FD4446 !important;
                            border-bottom: 2px solid #FD4446 !important;
                        }
                    }
                    .item-tab {
                        height: 100%;
                        margin-right: 25px;
                        cursor: pointer;
                        line-height: 81px;
                        border-bottom: 2px solid transparent;
                        font-size: 20px;
                        a {
                            color: #333333;
                            display: block;
                            &:hover {
                                color: #FD4446;
                            }
                        }
                    }
                }
                .countdown {
                    font-size: 18px;
                    color: #FD4446;
                    font-weight: 500;
                    .time {
                        margin-left: 10px;
                    }
                }
                .user-wrapper {
                    position: absolute;
                    right: 30px;
                    display: flex;
                    .account-balance {
                        display: flex;
                        align-items: center;
                        color: #FD4446;
                        font-weight: bold;
                        margin-right: 24px;
                    }
                    .user-info {
                        display: flex;
                        align-items: center;
                        .user-avatar {
                            width: 32px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 1px solid #eee;
                            box-sizing: border-box;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .user-name {
                            margin-left: 6px;
                        }
                    }
                    .exit-btn {
                        margin-left: 24px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: all .3s;
                        .iconfont {
                            font-size: 20px;
                        }
                        span {
                            margin-left: 6px;
                        }
                        &:hover {
                            color: #1122D8;
                        }
                    }
                }
            }
            /deep/ .right-content {
                height: 1%;
                flex: 1;
                .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        min-width: 900px;
                        /*height: 100%;*/
                    }
                    .right-wrapper-content {
                        padding: 20px 30px 20px 40px;
                        background: #F7F9FB;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
    /deep/ p {
        margin: 0;
    }
    .endPromotion{
        padding: 0 20px 0 42px;
        color: #ffffff;
        font-size: 17px;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
        transition: 0.5s;
        i{
            font-size: 18px;
        }
        span{
            margin-left: 20px;
        }
    }
    .endPromotion:hover{
        background: #25354F;
    }
    .train-dialog-content {
        display: flex;
        flex-direction: column;
        line-height: 2;
    }
</style>